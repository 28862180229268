import React, { useState } from 'react'
import Typography, {
  TypographyTypes,
} from '@yaak/components/src/Typography/Typography'
import style from './style.less'
import Switch from '@yaak/components/src/Switch'
import {
  ChartSettings as CSettings,
  MetadataKeys,
  useMetadataStore,
} from '../../stores/MetadataStore'
import { useShallow } from 'zustand/react/shallow'

interface DataSetLineProps {
  label: MetadataKeys
  color: string
}

const DataSetLine = ({ label, color }: DataSetLineProps) => {
  const { chartSettings, updateChartSettings } = useMetadataStore(
    useShallow((state) => ({
      chartSettings: state.chartSettings,
      updateChartSettings: state.updateChartSettings,
    }))
  )

  const [checked, setChecked] = useState(
    chartSettings[label as keyof CSettings].display
  )
  return (
    <>
      <div className={style.row}>
        <div className={style.row}>
          <span className={style.dot} style={{ background: color }} />
          <Typography className={style.label} type={TypographyTypes.label}>
            {label}
          </Typography>
        </div>
        <Switch
          checked={checked}
          onChange={() => {
            setChecked(!checked)
            updateChartSettings({
              ...chartSettings,
              [label]: {
                ...chartSettings[label as keyof CSettings],
                display: !checked,
              },
            })
          }}
        />
      </div>
    </>
  )
}

const ChartSettings = () => (
  <div className={style.settings}>
    <div className={style.row}>
      <Typography className={style.label} type={TypographyTypes.label}>
        VEHICLE MOTION
      </Typography>
    </div>
    <DataSetLine label={'gas_pedal_normalized'} color={'#ff9c57'} />
    <DataSetLine label={'brake_pedal_normalized'} color={'#6faeff'} />
    <DataSetLine label={'steering_angle_normalized'} color={'#ff82b7'} />
    <DataSetLine label={'speed'} color={'#d66700'} />
    <DataSetLine label={'steering_angle'} color={'#00315d'} />
    <DataSetLine label={'acceleration'} color={'#884fd8'} />
  </div>
)

export default ChartSettings
