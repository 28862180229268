import React, { ReactElement } from 'react'
import style from './style.less'
import DrivesOverview from '@yaak/components/src/DrivesOverview'
import { useNavigate, useSearchParams } from 'react-router-dom'

interface ScenariosOverviewProps {
  token: string
}

const ScenariosOverview: React.FunctionComponent<ScenariosOverviewProps> = ({
  token,
}): ReactElement => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const context = searchParams.get('context')

  return (
    <div className={style.scenariosOverview}>
      <DrivesOverview
        scenarios={true}
        token={token}
        nutron={true}
        fixedColumns={1}
        onClick={(sId: string, begin?: number, end?: number) => {
          context &&
            navigate(
              `/dataset/drives/${sId}?begin=${begin}&end=${end}&context=${parseInt(
                context
              )}&offset=${begin}`
            )
        }}
        withHeader={false}
        searchQueryEnabled={true}
      />
    </div>
  )
}

export default ScenariosOverview
