import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import 'mapbox-gl/dist/mapbox-gl.css'
import style from './style.less'
import { GeoFeature, useVideosStore } from '../../stores/VideosStore'
import mapboxgl from 'mapbox-gl'
import { usePlayerStore } from '../../stores/PlayerStore'
import { Feature, GeoJsonProperties, Geometry } from 'geojson'
import config from '@yaak/components/services/api/config'
import { useMetadataStore } from '../../stores/MetadataStore'
import {
  CurriculumLineString,
  CurriculumPoint,
  Gnss,
  Metadata,
  SafetyScore,
  Way,
} from '../../utils/protobufParse'
import {
  Event,
  getEvents,
  getIncidentOutcomes,
  IncidentOutcome,
  IncidentOutcomes,
} from '@yaak/components/services/api/api'
import {
  ToastContext,
  ToastContextType,
} from '@yaak/components/context/toastContext'
import { useShallow } from 'zustand/react/shallow'
import { ROAD_TYPE, SURFACE } from '../../utils/osm'
import { useParams, useSearchParams } from 'react-router-dom'

mapboxgl.accessToken = config.mapbox.accessToken

const CURRICULUM_ITEM_TYPE: Record<number, string> = {
  0: 'RIGHT_BEFORE_LEFT',
  1: 'GIVE_WAY',
  2: 'PEDESTRIAN_CROSSING',
  3: 'BUS_STOP',
  4: 'TRAIN_CROSSING',
  5: 'TRAFFIC_CALMER',
  6: 'LOWERED_KERB',
  7: 'RIGHT_TURN_ON_RED',
  8: 'UNKNOWN',
}

const CURRICULUM_LINE_TYPE: Record<number, string> = {
  0: 'UNPROTECTED_LEFT',
  1: 'LEFT',
  2: 'UNPROTECTED_RIGHT_WITH_BIKE',
  3: 'PROTECTED_RIGHT_WITH_BIKE',
  4: 'PROTECTED_LEFT',
  5: 'MULTILANE_LEFT',
  6: 'MULTILANE_RIGHT',
  7: 'ROUNDABOUT',
  8: 'LIMITED_ACCESS_WAY',
  9: 'LIVING_STREET',
  10: 'LOW_SPEED_REGION',
  11: 'ONE_WAY',
  12: 'TRAM_TRACKS',
  13: 'PRIORITY_FORWARD_BACKWARD',
  14: 'HILL_DRIVE',
  15: 'ROAD_NARROWS',
  16: 'ENTERING_MOVING_TRAFFIC',
  17: 'MERGE_IN_OUT_ON_HIGHWAY',
  18: 'PRIORITY_WAY',
  19: 'TUNNEL',
  20: 'WAY',
  21: 'UNKNOWN',
  22: 'PARKING',
  23: 'LANE_CHANGE',
}

const findScore = (scores: SafetyScore[], seconds: number) =>
  scores.filter((s) => s.clip.end_timestamp.seconds === seconds)[0]

const removeLayer = (map: any, id: string) => {
  if (map.getLayer(id)) {
    map.removeLayer(id)
  }
  if (map.getSource(id)) {
    map.removeSource(id)
  }
}

interface addRouteProps {
  id: string
  map: mapboxgl.Map | null
  route: any
  lineColor: string
}

const addRoute = ({ id, map, route, lineColor }: addRouteProps) => {
  if (map && route) {
    removeLayer(map, id)

    map.addSource(id, {
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          coordinates: route.geometry.coordinates,
          type: 'LineString',
        },
      },
      type: 'geojson',
    })

    map.addLayer({
      id,
      layout: {
        'line-cap': 'round',
      },
      paint: {
        'line-color': lineColor,
        'line-opacity': 0.75,
        'line-width': 5,
      },
      source: id,
      type: 'line',
    })
    zoomToGeoSource({ map, feature: route.geometry })
  }
}

interface zoomToGeoSourceProps {
  map: mapboxgl.Map
  feature: any
}

const zoomToGeoSource = ({ map, feature }: zoomToGeoSourceProps) => {
  map.fitBounds(
    [
      feature.coordinates[0],
      feature.coordinates[feature.coordinates.length - 1],
    ],
    {
      padding: 100,
    }
  )
}

interface addPointLayerProps {
  item: CurriculumLineString
  map: mapboxgl.Map
  metadata: Metadata
}

interface findIncidentDataProps {
  data: Way[]
  item: Gnss
}

const findIncidentData = ({ data, item }: findIncidentDataProps) =>
  data.filter(
    (d) =>
      item.time_stamp.seconds >= d.start.seconds &&
      item.time_stamp.seconds <= d.end.seconds
  )[0]

interface findMapFeatureDataProps {
  data: Way[]
  item: CurriculumPoint
}

const findMapFeatureData = ({ data, item }: findMapFeatureDataProps) => {
  return data.filter((d) => {
    return d.start.seconds === item.timestamp.seconds
  })[0]
}

interface findMapFeatureLineDataProps {
  data: Way[]
  item: CurriculumLineString
}

const findMapFeatureLineData = ({
  data,
  item,
}: findMapFeatureLineDataProps) => {
  return data.filter((d) => {
    return (
      d.start.seconds >= item.start.seconds && d.end.seconds <= item.end.seconds
    )
  })[0]
}

const findIncidentByEvent = (event: Event, gnss: Gnss[]) => {
  return gnss.filter((g) => {
    return (
      Math.floor(new Date(event.startTimestamp).getTime() / 1000) ===
      g.time_stamp.seconds
    )
  })[0]
}

const findIncidentLabel = (
  event?: Event,
  incidentsOutcomes?: IncidentOutcome[]
) =>
  incidentsOutcomes?.filter(
    (incidentOutcome) => incidentOutcome.id === event?.tag
  )[0]?.slug

interface addMarkerClickEventProps {
  label?: string
  item: CurriculumPoint | Gnss
  way: Way[]
  point: mapboxgl.Marker
}

const updatePopup = (wayPoint: Way, popupText: string) => {
  if (wayPoint) {
    popupText += wayPoint.lanes ? `<p>lanes ${wayPoint.lanes}</p>` : ''
    popupText += wayPoint.maxspeed
      ? `<p>max speed ${wayPoint.maxspeed}</p>`
      : ''
    popupText += wayPoint.surface
      ? ` <p>road surface ${SURFACE[wayPoint.surface]}</p>`
      : ''
    popupText += `<p>road type ${ROAD_TYPE[wayPoint.highway]}</p>`
  }

  return popupText
}

const addMarkerClickEvent = ({
  label,
  item,
  way,
  point,
}: addMarkerClickEventProps) => {
  const markerDiv = point.getElement()
  markerDiv.addEventListener('mousedown', (event: MouseEvent) => {
    event.stopPropagation()
    let popupText = `<p>${label ? 'incident' : 'map feature'} ${
      label || CURRICULUM_ITEM_TYPE[(item as CurriculumPoint).type || 0]
    }</p>`

    const wayPoint = label
      ? findIncidentData({ data: way, item: item as Gnss })
      : findMapFeatureData({ data: way, item: item as CurriculumPoint })
    popupText = updatePopup(wayPoint, popupText)

    const popup = new mapboxgl.Popup({
      offset: 25,
      className: style.mapPopup,
      closeButton: false,
      closeOnMove: true,
    }).setHTML(`${popupText}`)
    event.stopPropagation()
    point.setPopup(popup)
    point.togglePopup()
  })
}

interface addMarkerHoverEventProps {
  point: mapboxgl.Marker
  popupText: string
}

const addMarkerHoverEvent = ({
  point,
  popupText,
}: addMarkerHoverEventProps) => {
  const markerDiv = point.getElement()
  markerDiv.addEventListener('mouseenter', (event: MouseEvent) => {
    const popup = new mapboxgl.Popup({
      offset: 25,
      className: style.mapPopup,
      closeButton: false,
      closeOnMove: true,
    }).setHTML(`${popupText}`)
    event.stopPropagation()
    point.setPopup(popup)
    point.togglePopup()
  })
  markerDiv.addEventListener('mouseleave', () => point.togglePopup())
}

interface addLineFeatureHoverEventProps {
  map: mapboxgl.Map
  popupText: string
  id: string
}

const addLineFeatureHoverEvent = ({
  map,
  popupText,
  id,
}: addLineFeatureHoverEventProps) => {
  const popup = new mapboxgl.Popup({
    offset: 25,
    className: style.mapPopup,
    closeButton: false,
    closeOnMove: true,
  }).setHTML(`${popupText}`)
  map.on('mousemove', `${id}_outline_0`, (e) => {
    popup.setLngLat(e.lngLat).addTo(map)
  })
  map.on('mouseleave', `${id}_outline_0}`, () => {
    popup.remove()
  })
}

interface addLineFeatureClickEventProps {
  map: mapboxgl.Map
  id: string
  item: CurriculumLineString
  way: Way[]
}

const addLineFeatureClickEvent = ({
  map,
  id,
  item,
  way,
}: addLineFeatureClickEventProps) => {
  const popup = new mapboxgl.Popup({
    offset: 25,
    className: style.mapPopup,
    closeButton: false,
    closeOnMove: true,
  })
  map.on('click', `${id}_outline_0`, (e) => {
    let popupText = `<p>map feature ${CURRICULUM_LINE_TYPE[item.type || 0]}</p>`
    const wayPoint = findMapFeatureLineData({ data: way, item })
    popupText = updatePopup(wayPoint, popupText)
    popup.setHTML(`${popupText}`)
    popup.setLngLat(e.lngLat).addTo(map)
  })
}

interface addMarkerProps {
  item: CurriculumPoint
  map: mapboxgl.Map
  markers: mapboxgl.Marker[]
  setMarkers: Dispatch<SetStateAction<mapboxgl.Marker[]>>
  metadata: Metadata
}

const addMarker = ({
  item,
  map,
  markers,
  setMarkers,
  metadata,
}: addMarkerProps) => {
  const el = document.createElement('div')
  const point: any = new mapboxgl.Marker(el)
    .setLngLat([item.location.longitude, item.location.latitude])
    .addTo(map)
  markers.push(point)
  setMarkers(markers)
  point.addClassName(style.point)
  point.setRotation(45)

  addMarkerClickEvent({
    item,
    way: metadata.way,
    point,
  })

  addMarkerHoverEvent({
    point,
    popupText: `<p>map feature ${CURRICULUM_ITEM_TYPE[item.type || 0]}</p>`,
  })
  return point
}

interface addIncidentProps {
  item: Gnss
  map: mapboxgl.Map
  incidentMarkers: mapboxgl.Marker[]
  setIncidentMarkers: Dispatch<SetStateAction<mapboxgl.Marker[]>>
  metadata: Metadata
  label?: string
}

const addIncident = ({
  label,
  item,
  map,
  incidentMarkers,
  setIncidentMarkers,
  metadata,
}: addIncidentProps) => {
  const el = document.createElement('div')
  const point: any = new mapboxgl.Marker(el)
    .setLngLat([item.longitude, item.latitude])
    .addTo(map)
  incidentMarkers.push(point)
  setIncidentMarkers(incidentMarkers)
  point.addClassName(style.incident)
  point.setRotation(45)

  addMarkerClickEvent({
    label,
    item,
    way: metadata.way,
    point,
  })

  addMarkerHoverEvent({
    point,
    popupText: `<p>incident ${label}</p>`,
  })
  return point
}

const addMarkerLine = ({ item, map, metadata }: addPointLayerProps) => {
  const id = `${item.start.seconds}_${item.start.nanos}`
  const geometry: any = item.locations.reduce((acc, item) => {
    acc.push([item.longitude, item.latitude])
    return acc
  }, [] as any)

  ;[-1, 0, 1, 0].forEach((x, i) => {
    map.addLayer({
      id: `${id}_outline_${i}`,
      type: 'line',
      source: {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: geometry,
          },
        } as Feature<Geometry, GeoJsonProperties>,
      },
      layout: {
        'line-cap': i === 3 ? 'butt' : 'square',
      },
      paint: {
        'line-color': i === 3 ? '#009e9e' : '#fff',
        'line-width': i === 3 ? 6 : 3,
        'line-offset': x * 3,
      },
    })
  })

  addLineFeatureHoverEvent({
    map,
    popupText: `<p>map feature ${CURRICULUM_LINE_TYPE[item.type || 0]}</p>`,
    id,
  })

  addLineFeatureClickEvent({
    map,
    id,
    item,
    way: metadata.way,
  })
}

const findGnssIndex = (e: mapboxgl.MapMouseEvent, gnss: Gnss[]) => {
  const coords = e.lngLat.wrap()
  return gnss.findIndex((g: Gnss) => {
    return (
      g.longitude?.toFixed(3) === coords.lng.toFixed(3) &&
      g.latitude?.toFixed(3) === coords.lat.toFixed(3)
    )
  })
}

const removeMarkersLayer = (map: mapboxgl.Map, id: string) => {
  removeLayer(map, id)
  ;[-1, 0, 1, 0].forEach((x, i) => {
    removeLayer(map, `${id}_outline_${i}`)
  })
}

interface MapProps {
  token: string
}

const Map: React.FunctionComponent<MapProps> = ({ token }) => {
  const { sessionId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const { setShowToast } = useContext(ToastContext) as ToastContextType
  const [map, setMap] = useState<mapboxgl.Map | null>(null)
  const [route, setRoute] = useState<GeoFeature>()
  const [incidents, setIncidents] = useState<Gnss[]>()
  const [incidentsOutcomes, setIncidentsOutcomes] = useState<IncidentOutcomes>()
  const [events, setEvents] = useState<Event[]>()
  const mapContainer = useRef<HTMLDivElement>(null)
  const { session } = useVideosStore()
  const [point, setPoint] = useState<any>()
  const [hoverPoint, setHoverPoint] = useState<any>()
  const [coordinates, setCoordinates] = useState<number[]>()
  const [heading, setHeading] = useState<number>(0)
  const { begin, context, offset, update, hoverSync } = usePlayerStore(
    useShallow((state) => ({
      begin: state.begin,
      offset: state.offset,
      context: state.context,
      update: state.update,
      hoverSync: state.hoverSync,
    }))
  )
  const { mapSettings, metadata, seconds, loadingFinished } = useMetadataStore(
    useShallow((state) => ({
      mapSettings: state.mapSettings,
      seconds: state.seconds,
      metadata: state.metadata,
      loadingFinished: state.loadingFinished,
    }))
  )
  const [markers, setMarkers] = useState<mapboxgl.Marker[]>([])
  const [incidentMarkers, setIncidentMarkers] = useState<mapboxgl.Marker[]>([])

  useEffect(() => {
    const fetchData = async () => {
      if (sessionId) {
        const events = await getEvents({
          token,
          sessionId,
          onAlert: setShowToast,
        })
        setEvents(events.data)
        const incidents = events.data.map((event) =>
          findIncidentByEvent(event, metadata.gnss)
        )
        setIncidents(incidents)
      }
    }
    token && sessionId && loadingFinished && fetchData()
  }, [sessionId, token, metadata, loadingFinished])

  useEffect(() => {
    const fetchIncidentOutcomes = async () => {
      const incidentsOutcomes = await getIncidentOutcomes({
        token,
        onAlert: setShowToast,
      })
      setIncidentsOutcomes(incidentsOutcomes)
    }

    token && fetchIncidentOutcomes()
  }, [token])

  useEffect(() => {
    if (metadata.gnss?.length > 0 && loadingFinished) {
      const coordinates = metadata.gnss.map((g) => [
        g.longitude?.toFixed(6),
        g.latitude?.toFixed(6),
      ])
      const route: any = {
        geometry: { type: 'MultiLineString', coordinates },
        type: 'Feature',
      }
      setRoute(route)
    }
  }, [metadata])

  useEffect(() => {
    if (mapContainer.current && route && session) {
      const newMap = new mapboxgl.Map({
        center: route.geometry.coordinates[0],
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/light-v9',
        zoom: 5,
      })

      newMap.on('load', () => {
        setMap(newMap)
        newMap.resize()
      })

      return () => {
        newMap.remove()
      }
    }
  }, [route, session])

  useEffect(() => {
    if (map) {
      addRoute({
        id: 'route',
        map,
        route,
        lineColor: '#000',
      })
    }
  }, [map, route])

  useEffect(() => {
    if (map && begin) {
      const gnss = metadata.gnss
      const value = gnss?.filter((g) => {
        const startTime = gnss[0].time_stamp.seconds + context
        const endTime = gnss[gnss.length - 1].time_stamp.seconds - context
        return (
          g.time_stamp.seconds >= startTime && g.time_stamp.seconds <= endTime
        )
      })
      if (value.length > 0) {
        const coordinates = value.map((g) => [
          g.longitude.toFixed(6),
          g.latitude.toFixed(6),
        ])
        const scenarioRoute: any = {
          geometry: { type: 'MultiLineString', coordinates },
          type: 'Feature',
        }
        addRoute({
          id: 'scenario',
          map,
          route: scenarioRoute,
          lineColor: '#7e84fc',
        })
      }
    }
  }, [map, route, context, metadata, begin])

  useEffect(() => {
    if (map && route && route.geometry.coordinates.length) {
      if (!point) {
        if (route.geometry.coordinates[0][0] && metadata.gnss?.length > 0) {
          const el = document.createElement('div')
          const point: any = new mapboxgl.Marker(el)
            .setLngLat([0, 0])
            .addTo(map)
          point.addClassName(style.marker)
          setPoint(point)
        }
      }

      if (!hoverPoint) {
        const el = document.createElement('div')
        const hP: any = new mapboxgl.Marker(el).setLngLat([0, 0]).addTo(map)
        hP.addClassName(style.hoverMarker)
        hP.addClassName(style.hoverMarkerHide)
        setHoverPoint(hP)
      }
    }
  }, [map, route, metadata, point, hoverPoint])

  useEffect(() => {
    if (map && loadingFinished) {
      if (mapSettings.map_features.display) {
        metadata?.curriculumPoint?.forEach((item) => {
          addMarker({
            item,
            map,
            markers,
            metadata,
            setMarkers,
          })
        })
      } else {
        for (let i = 0; i < markers.length; i++) {
          markers[i].remove()
        }
      }
    }
  }, [metadata, map, loadingFinished, mapSettings, markers])

  useEffect(() => {
    if (map && loadingFinished) {
      metadata?.curriculumLineString?.forEach((item) => {
        const id = `${item.start.seconds}_${item.start.nanos}`
        removeMarkersLayer(map, id)
        if (mapSettings.map_features.display) {
          addMarkerLine({
            item,
            map,
            metadata,
          })
        } else {
          removeMarkersLayer(map, id)
          for (let i = 0; i < markers.length; i++) {
            markers[i].remove()
          }
        }
      })
    }
  }, [map, loadingFinished, mapSettings, markers])

  useEffect(() => {
    if (map && loadingFinished) {
      if (mapSettings.incidents.display) {
        incidents?.forEach((item, i) => {
          item &&
            addIncident({
              label: findIncidentLabel(events?.[i], incidentsOutcomes?.data),
              item,
              map,
              incidentMarkers,
              setIncidentMarkers,
              metadata,
            })
        })
      } else {
        for (let i = 0; i < incidentMarkers.length; i++) {
          incidentMarkers[i].remove()
        }
      }
    }
  }, [
    incidents,
    map,
    loadingFinished,
    mapSettings,
    incidentMarkers,
    incidentsOutcomes,
    metadata,
  ])

  useEffect(() => {
    const gnss = metadata.gnss
    if (
      map &&
      gnss?.length > 0 &&
      seconds &&
      seconds.length > 0 &&
      loadingFinished
    ) {
      map.on('click', (e) => {
        const index = findGnssIndex(e, gnss)
        if (index && gnss[index]) {
          const offset =
            gnss[index].time_stamp.seconds - gnss[0].time_stamp.seconds
          update({
            jump: offset,
          })
          searchParams.set('offset', offset.toString())
          setSearchParams(searchParams)
        }
      })
    }
  }, [map, metadata, seconds, loadingFinished, update])

  useEffect(() => {
    const gnss = metadata.gnss
    if (map && gnss?.length > 0) {
      map.on('mousemove', (e) => {
        const index = findGnssIndex(e, gnss)
        if (index && gnss[index]) {
          update({
            hoverSync:
              gnss[index].time_stamp.seconds - gnss[0].time_stamp.seconds,
          })
        }
      })
      map.on('mouseleave', () => {
        update({
          hoverSync: null,
        })
      })
    }
  }, [map, metadata, update])

  useEffect(() => {
    const gnss = metadata.gnss
    if (map && gnss?.length > 0 && seconds && seconds.length > 0) {
      const time = gnss[0].time_stamp.seconds + offset - begin + context
      const index = seconds?.findIndex((s) => s === (time | 0))
      const position = index !== -1 ? gnss[index] : gnss[0]
      if (position) {
        setCoordinates([position.longitude, position.latitude])
        setHeading(position.heading)
      }
    }
  }, [map, metadata, offset, seconds])

  useEffect(() => {
    if (
      coordinates &&
      coordinates.length > 0 &&
      point &&
      coordinates[0] &&
      coordinates[1]
    ) {
      const time = metadata.gnss[0].time_stamp.seconds + offset
      const seconds = Math.floor(time)
      const safetyScore = findScore(metadata.safetyScore, seconds)
      point.setLngLat(coordinates)

      if (safetyScore?.score) {
        const popup = new mapboxgl.Popup({
          offset: 5,
          className: style.scorePopup,
          closeButton: false,
        }).setText(`${Math.floor(safetyScore.score * 100)}%`)
        point.setPopup(popup)
        point.togglePopup()
      } else {
        point.togglePopup()
      }
      point.setRotation(heading)
    }
  }, [point, coordinates, metadata, heading, offset])

  useEffect(() => {
    const gnss = metadata.gnss
    const value = gnss?.filter(
      (g) =>
        g.time_stamp.seconds ===
        parseInt(
          (gnss[0]?.time_stamp.seconds + hoverSync - begin + context).toFixed(0)
        )
    )[0]
    if (hoverSync && value) {
      hoverPoint?.removeClassName(style.hoverMarkerHide)
      hoverPoint?.setLngLat([value.longitude, value.latitude])
      const time = gnss[0].time_stamp.seconds + hoverSync
      const seconds = Math.floor(time)
      const safetyScore = findScore(metadata.safetyScore, seconds)
      const wayPoint = metadata.way.filter((d) => {
        return d.start.seconds === seconds
      })[0]

      let popupText = ''

      if (safetyScore) {
        popupText = `${Math.floor(safetyScore.score * 100)}%`
      }

      if (wayPoint) {
        popupText = updatePopup(wayPoint, popupText)
      }

      if (popupText) {
        const popup = new mapboxgl.Popup({
          offset: 5,
          className: style.scorePopup,
          closeButton: false,
        }).setHTML(popupText)
        hoverPoint?.setPopup(popup)
        hoverPoint?.togglePopup()
      } else {
        hoverPoint?.togglePopup()
      }
      hoverPoint?.setRotation(value.heading)
    } else {
      hoverPoint?.addClassName(style.hoverMarkerHide)
      hoverPoint?.togglePopup()
    }
  }, [hoverSync, metadata, hoverPoint, begin, context])

  return <div className={style.map} ref={mapContainer} />
}

export default Map
