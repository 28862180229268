import React, { useState, useEffect } from 'react'
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import Router from './Routes'
import Toast, { toastType } from '@yaak/components/src/Toast/Toast'
import { ToastContext } from '@yaak/components/context/toastContext'
import style from './style.less'
import classNames from 'classnames'

import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import en from '@yaak/ds-admin/src/lang/en.json'
import { IntlProvider } from 'react-intl'
import Profile from '@yaak/components/src/Profile'
import bundledRelease from '../public/release.json'
import Header from './components/Header'

export const LIGHT_THEME = 'light'
export const DARK_THEME = 'dark'

interface ThemeTypeContext {
  theme: string
  setTheme: React.Dispatch<React.SetStateAction<string>>
}
export const ThemeContext = React.createContext<ThemeTypeContext>({
  theme: DARK_THEME,
  setTheme: () => {},
})

const App = () => {
  const [token, setToken] = useState<string>('')
  const [toast, setShowToast] = useState<toastType | null>(null)
  const [theme, setTheme] = useState(DARK_THEME)

  const { getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    const getToken = async () => {
      const token = await getAccessTokenSilently()
      setToken(token)
    }
    getToken()
  }, [])

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <IntlProvider locale={'en'} messages={en}>
        <ToastContext.Provider value={{ toast, setShowToast }}>
          <div className={classNames(style[theme], style.app)}>
            <Profile bundledRelease={bundledRelease} />
            <div className={style.main}>
              <Header />
              <Router token={token} />
              {toast && <Toast toast={toast} setShowToast={setShowToast} />}
            </div>
          </div>
        </ToastContext.Provider>
      </IntlProvider>
    </ThemeContext.Provider>
  )
}

export default withAuthenticationRequired(App, {})
