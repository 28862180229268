import React, { ReactElement, useContext, useEffect, useState } from 'react'
import style from './style.less'
import Typography, {
  TypographyTypes,
} from '@yaak/components/src/Typography/Typography'
import DoughnutChart from '../../components/MetricCharts/DoughnutChart'
import HorizontalBarChart from '../../components/MetricCharts/HorizontalBarChart'
import BarChart from '../../components/MetricCharts/BarChart'
import {
  DriveMetric,
  getMetrics,
  IncidentMetric,
  LanesMetric,
  LightningMetric,
  MapFeaturesMetric,
  RoadTypeMetric,
  SpeedHistogram,
  WeatherMetric,
} from '@yaak/components/services/api/api'
import {
  ToastContext,
  ToastContextType,
} from '@yaak/components/context/toastContext'
import { toHoursMinutesSecondsFromSeconds } from '@yaak/admin/src/helpers/time'

const MAX_LEGEND_ITEMS = 7

interface MetricsPagePageProps {
  token: string
}

interface MetricsData {
  legend: string[]
  data: number[]
  distance?: number
  duration?: number
}

interface Metrics {
  weather: MetricsData
  lightning: MetricsData
  roadType: MetricsData
  lanes: MetricsData
  driving: MetricsData
  incidents: MetricsData
  mapFeatures: MetricsData
  avgDriveDuration: MetricsData
  speed: MetricsData
}

type MetricProperty = 'condition' | 'type' | 'driveType' | 'tag' | 'feature'

type PropertiesAll =
  | WeatherMetric
  | LightningMetric
  | RoadTypeMetric
  | DriveMetric
  | IncidentMetric
  | MapFeaturesMetric

const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1)

const sortMetrics = (metrics: PropertiesAll[], property: MetricProperty): any =>
  metrics.sort((a, b) =>
    a[property as keyof PropertiesAll] > b[property as keyof PropertiesAll]
      ? 1
      : b[property as keyof PropertiesAll] > a[property as keyof PropertiesAll]
      ? -1
      : 0
  )

const calculateDistance = (metrics: RoadTypeMetric[]) =>
  metrics.reduce((acc, m) => {
    acc += m.distance
    return acc
  }, 0) / 1000

const calculateDuration = (metrics: DriveMetric[]) =>
  Math.floor(
    metrics.reduce((acc, m) => {
      acc += m.duration
      return acc
    }, 0) / 3600
  )

const filterData = (metrics: DriveMetric[]) =>
  metrics.filter(
    (metric) => metric.driveType === 'EXPERT' || metric.driveType === 'STUDENT'
  )

const MetricsPage: React.FunctionComponent<MetricsPagePageProps> = ({
  token,
}): ReactElement => {
  const { setShowToast } = useContext(ToastContext) as ToastContextType
  const [metrics, setMetrics] = useState<Metrics>()

  useEffect(() => {
    const getMetricsData = async () => {
      const metrics = await getMetrics({
        token,
        onAlert: setShowToast,
      })
      const weatherMetrics: WeatherMetric[] = sortMetrics(
        metrics.weatherMetric,
        'condition'
      )
      const lightningMetrics: LightningMetric[] = sortMetrics(
        metrics.lightningMetric,
        'condition'
      )
      const roadTypeMetrics: RoadTypeMetric[] = sortMetrics(
        metrics.roadTypeMetric,
        'type'
      )
      const lanesMetrics: LanesMetric[] = metrics.lanesMetric
      const drivingMetrics: DriveMetric[] = sortMetrics(
        filterData(metrics.drivingMetric),
        'driveType'
      )
      const incidentMetrics: IncidentMetric[] = sortMetrics(
        metrics.incidentMetric,
        'tag'
      )
      const mapFeatures: MapFeaturesMetric[] = sortMetrics(
        metrics.mapFeaturesMetric,
        'feature'
      )
      const avgDriveDurations: DriveMetric[] = sortMetrics(
        filterData(metrics.avgDriveDurationMetric),
        'driveType'
      )
      const speedHistogram: SpeedHistogram[] = metrics.speedHistogram
      setMetrics({
        weather: {
          legend: weatherMetrics.map((weatherMetric) =>
            capitalize(weatherMetric.condition)
          ),
          data: weatherMetrics.map((weatherMetric) => weatherMetric.duration),
        },
        lightning: {
          legend: lightningMetrics.map((lightningMetric) =>
            capitalize(lightningMetric.condition)
          ),
          data: lightningMetrics.map(
            (lightningMetric) => lightningMetric.duration
          ),
        },
        roadType: {
          legend: roadTypeMetrics.map((roadTypeMetric) =>
            capitalize(roadTypeMetric.type)
          ),
          data: roadTypeMetrics.map(
            (roadTypeMetric) => roadTypeMetric.distance / 1000
          ),
          distance: calculateDistance(roadTypeMetrics),
        },
        lanes: {
          legend: lanesMetrics.map((lanesMetric) => lanesMetric.lanes),
          data: lanesMetrics.map((lanesMetric) => lanesMetric.duration),
        },
        driving: {
          legend: drivingMetrics.map(
            (drivingMetric) => drivingMetric.driveType
          ),
          data: drivingMetrics.map((drivingMetric) =>
            Math.floor(drivingMetric.duration / 3600)
          ),
          duration: calculateDuration(drivingMetrics),
        },
        incidents: {
          legend: incidentMetrics.map((incidentMetric) => incidentMetric.tag),
          data: incidentMetrics.map((incidentMetric) => incidentMetric.count),
        },
        mapFeatures: {
          legend: mapFeatures.map((mapFeature) => mapFeature.feature),
          data: mapFeatures.map((mapFeature) => mapFeature.count),
        },
        avgDriveDuration: {
          legend: avgDriveDurations.map(
            (avgDriveDuration) => avgDriveDuration.driveType
          ),
          data: avgDriveDurations.map((avgDriveDuration) =>
            Math.floor(avgDriveDuration.duration / 60)
          ),
        },
        speed: {
          legend: speedHistogram.map(
            (speed) =>
              `${Math.floor(speed.minSpeed)}-${Math.floor(
                speed.maxSpeed
              )} (km/h)`
          ),
          data: speedHistogram.map((speed) => speed.duration),
        },
      })
    }
    token && getMetricsData()
  }, [token])

  return metrics ? (
    <div className={style.metricsOverview}>
      <div className={style.left}>
        <Typography type={TypographyTypes.body}>Dynamic data</Typography>
        <div className={style.top}>
          <div className={style.topLeft}>
            <BarChart
              title={'Speed'}
              data={metrics.speed.data}
              labels={metrics.speed.legend}
              yTitle="HH:MM:SS"
              ticks={{
                callback: (duration: any) =>
                  toHoursMinutesSecondsFromSeconds(duration),
                // display 5 steps with 1h accuracy
                stepSize:
                  Math.ceil(Math.max(...metrics.speed.data) / 3600 / 5) * 3600,
              }}
            />
          </div>
          <div className={style.topRight}>
            {metrics.lightning && (
              <HorizontalBarChart
                title={'Lighting'}
                labels={['']}
                data={metrics.lightning.data}
                legend={metrics.lightning.legend}
                stacked
                formatValue={toHoursMinutesSecondsFromSeconds}
              />
            )}
            {metrics.weather && (
              <HorizontalBarChart
                title={'Weather'}
                labels={['']}
                data={metrics.weather.data}
                legend={metrics.weather.legend}
                stacked
                formatValue={toHoursMinutesSecondsFromSeconds}
              />
            )}
          </div>
        </div>
        <div className={style.bottom}>
          <div className={style.bottomLeft}>
            <Typography type={TypographyTypes.body}>Map-based data</Typography>
            <div className={style.doughnutsContainer}>
              <DoughnutChart
                title={'Road type'}
                data={metrics.roadType.data}
                labels={metrics.roadType.legend}
                centerText={`${metrics.roadType.distance}/Kilometers`}
                displayLegend={
                  metrics.roadType.legend.length <= MAX_LEGEND_ITEMS
                }
                unit={'km'}
              />
            </div>
            <HorizontalBarChart
              title={'Lane count'}
              labels={['']}
              data={metrics.lanes.data}
              legend={metrics.lanes.legend}
              stacked
              formatValue={toHoursMinutesSecondsFromSeconds}
            />
          </div>
          <div className={style.bottomRight}>
            <DoughnutChart
              title={'Map features'}
              data={metrics.mapFeatures.data}
              labels={metrics.mapFeatures.legend}
              displayLegend={
                metrics.mapFeatures.legend.length <= MAX_LEGEND_ITEMS
              }
            />
          </div>
        </div>
      </div>
      <div className={style.right}>
        <Typography type={TypographyTypes.body}>Drive KPIs</Typography>
        <div className={style.boxContainer}>
          <div className={style.box}>
            <div className={style.doughnutsContainer}>
              <DoughnutChart
                title={'Incidents'}
                data={metrics.incidents.data}
                labels={metrics.incidents.legend}
                displayLegend={
                  metrics.incidents.legend.length <= MAX_LEGEND_ITEMS
                }
              />
            </div>
            <div className={style.doughnutsContainer}>
              <DoughnutChart
                title={'Driving hours'}
                labels={metrics.driving.legend}
                data={metrics.driving.data}
                centerText={`${metrics.driving.duration}/Hours`}
                displayLegend={
                  metrics.driving.legend.length <= MAX_LEGEND_ITEMS
                }
              />
            </div>
          </div>
          <HorizontalBarChart
            title={'Average drive duration'}
            footer={'Time (min)'}
            labels={metrics.avgDriveDuration.legend}
            data={metrics.avgDriveDuration.data}
          />
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default MetricsPage
