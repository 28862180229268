// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style__header--uQQcP {\n  cursor: pointer;\n  display: flex;\n  gap: 1rem;\n  padding: 1rem 0 0 2rem;\n}\n.style__headerOpacity--_IVZc {\n  opacity: 55%;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Header/style.less"],"names":[],"mappings":"AAEA;EAEI,eAAA;EACA,aAAA;EACA,SAAA;EACA,sBAAA;AAFJ;AAHA;EASI,YAAA;AAHJ","sourcesContent":["@import '@yaak/components/src/spacing';\n\n:local {\n  .header {\n    cursor: pointer;\n    display: flex;\n    gap: @spacing-100;\n    padding: @spacing-100 0 0 @spacing-200;\n  }\n\n  .headerOpacity {\n    opacity: 55%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "style__header--uQQcP",
	"headerOpacity": "style__headerOpacity--_IVZc"
};
export default ___CSS_LOADER_EXPORT___;
