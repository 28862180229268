// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style__form--pwqA3 {\n  width: 8rem;\n}\n.style__form--pwqA3 > label {\n  margin-top: -0.5rem;\n}\n.style__select--qAmdy > div {\n  padding: 7px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/AddPanelSelect/style.less"],"names":[],"mappings":"AAAA;EAEI,WAAA;AAAJ;AAFA;EAKM,mBAAA;AAAN;AALA;EAUM,YAAA;AAFN","sourcesContent":[":local {\n  .form {\n    width: 8rem;\n\n    > label {\n      margin-top: -0.5rem;\n    }\n  }\n  .select {\n    > div {\n      padding: 7px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "style__form--pwqA3",
	"select": "style__select--qAmdy"
};
export default ___CSS_LOADER_EXPORT___;
