import React, { useRef } from 'react'
import { Chart as ChartJS, BarElement, Title, LinearScale } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import style from './style.less'
import { toHoursMinutesSecondsFromSeconds } from '@yaak/admin/src/helpers/time'

ChartJS.register(BarElement, Title, LinearScale)

interface BarChartProps {
  title: string
  data: number[]
  footer?: string
  ticks?: {
    callback: (value: any) => string | undefined
    stepSize: number
  }
  yTitle?: string
  labels: string[]
}

const colors = ['#9fa3ff']

const BarChart = ({
  title,
  data,
  footer,
  labels,
  ticks,
  yTitle,
}: BarChartProps) => {
  const chartRef = useRef<any>(null)

  const chartData = {
    labels,
    datasets: [
      {
        label: 'speed',
        data,
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1,
        barThickness: 'flex',
      } as any,
    ],
  }

  const options = {
    animation: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        align: 'center',
        display: true,
        text: title,
      },
      tooltip: {
        callbacks: {
          label: (item: any) => toHoursMinutesSecondsFromSeconds(item.raw),
        },
      },
    },
    type: 'bar',
    data: chartData,
    scales: {
      x: {
        title: {
          display: !!footer,
          text: footer,
        },
      },
      y: {
        title: {
          display: !!yTitle,
          text: yTitle,
        },
        ticks,
      },
    },
  }

  return (
    <div className={style.bar}>
      <Bar ref={chartRef} options={options as any} data={chartData} />
    </div>
  )
}

export default BarChart
