import React, { useState } from 'react'
import Typography, {
  TypographyTypes,
} from '@yaak/components/src/Typography/Typography'
import style from './style.less'
import Switch from '@yaak/components/src/Switch'
import {
  MetadataSettings as MSettings,
  useMetadataStore,
} from '../../stores/MetadataStore'

interface DataSetLineProps {
  label: string
}

const MetadataSetting = ({ label }: DataSetLineProps) => {
  const { metadataSettings, updateMetadataSettings } = useMetadataStore()
  const [checked, setChecked] = useState(
    metadataSettings[label as keyof MSettings].display
  )
  return (
    <div className={style.row}>
      <div className={style.row}>
        <span
          className={style.dot}
          style={{
            background: metadataSettings[label as keyof MSettings].color,
          }}
        />
        <Typography className={style.label} type={TypographyTypes.label}>
          {label}
        </Typography>
      </div>
      <Switch
        checked={checked}
        onChange={() => {
          setChecked(!checked)
          updateMetadataSettings({
            ...metadataSettings,
            [label]: {
              ...metadataSettings[label as keyof MSettings],
              display: !checked,
            },
          })
        }}
      />
    </div>
  )
}

const MetadataSettings = () => (
  <div className={style.settings}>
    <div className={style.row}>
      <Typography className={style.label} type={TypographyTypes.label}>
        METADATA
      </Typography>
    </div>
    <MetadataSetting label={'steering_angle_normalized'} />
    <MetadataSetting label={'steering_angle'} />
    <MetadataSetting label={'acceleration'} />
    <MetadataSetting label={'gas_pedal_normalized'} />
    <MetadataSetting label={'brake_pedal_normalized'} />
    <MetadataSetting label={'gear'} />
    <MetadataSetting label={'speed'} />
    <MetadataSetting label={'turn_signal'} />
    <MetadataSetting label={'hp_loc_latitude'} />
    <MetadataSetting label={'hp_loc_longitude'} />
    <MetadataSetting label={'hp_loc_altitude'} />
    <MetadataSetting label={'heading'} />
    <MetadataSetting label={'position_covariance'} />
    <MetadataSetting label={'heading_error'} />
  </div>
)

export default MetadataSettings
