import React, { useRef } from 'react'
import {
  Chart as ChartJS,
  Title,
  Legend,
  LinearScale,
  ArcElement,
  Tooltip,
} from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import style from './style.less'
import { tooltipCallbacks, DOUGHNUT_CHART_COLORS, DEFAULT_COLOR } from './util'

ChartJS.register(Title, Legend, LinearScale, ArcElement, Tooltip)

interface DoughnutChartProps {
  labels: string[]
  title: string
  data: number[]
  centerText?: string
  displayLegend: boolean
  unit?: string
}

const DoughnutChart = ({
  title,
  labels,
  data,
  centerText,
  displayLegend = true,
  unit = '',
}: DoughnutChartProps) => {
  const chartRef = useRef<any>(null)

  const chartData = {
    labels,
    datasets: [
      {
        data,
        backgroundColor: labels.map(
          (i) => DOUGHNUT_CHART_COLORS[i] || DEFAULT_COLOR
        ),
        borderColor: labels.map(
          (i) => DOUGHNUT_CHART_COLORS[i] || DEFAULT_COLOR
        ),
        borderWidth: 1,
      },
    ],
  }

  const options = {
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: displayLegend,
        position: 'right',
        labels: {
          boxHeight: 10,
          boxWidth: 10,
          font: {
            size: 10,
          },
        },
      },
      title: {
        align: 'start',
        display: true,
        text: title,
      },
      tooltip: {
        callbacks: tooltipCallbacks({
          unit,
        }),
      },
    },
    indexAxis: 'y',
    type: 'doughnut',
  }

  const plugins = [
    {
      id: 'centerText',
      beforeDraw: (chart: ChartJS) => {
        const legend = chart.legend
        const width = chart.width
        const height = chart.height
        const ctx = chart.ctx
        ctx.restore()
        const fontSize = (height / 400).toFixed(2)
        ctx.font = fontSize + 'em sans-serif'
        ctx.textBaseline = 'top'
        const text = centerText?.split('/')[0] || ''
        const text2 = centerText?.split('/')[1] || ''
        const textX = Math.round(
          (width - (legend?.width || 0) - ctx.measureText(text).width) / 2
        )
        const text2X = Math.round(
          (width - (legend?.width || 0) - ctx.measureText(text2).width) / 2
        )
        const textY = height / 2 + 10
        const text2Y = height / 2 + 20
        ctx.fillText(text, textX, textY)
        ctx.fillText(text2, text2X, text2Y)
        ctx.save()
      },
    },
  ]

  return (
    <div className={style.doughnut}>
      <Doughnut
        ref={chartRef}
        options={options as any}
        data={chartData as any}
        plugins={centerText ? (plugins as any) : undefined}
      />
    </div>
  )
}

export default DoughnutChart
