import React, { RefObject } from 'react'
import style from './style.less'
import Icon from '@yaak/components/src/Icon/Icon'
import { MosaicParent } from 'react-mosaic-component/src/types'
import {
  PANEL_ID_CHART,
  PANEL_ID_MAP,
  PANEL_ID_METADATA,
  LAYOUT_NODE,
} from '../../../stores/MosaicStore'
import { DEFAULT_CONTROLS_WITH_CREATION } from 'react-mosaic-component'

interface Controls {
  id: string
  update: (state: any) => void
  config: MosaicParent<LAYOUT_NODE>
  setPanelId: React.Dispatch<React.SetStateAction<string | undefined>>
}

interface ControlsWithRef extends Controls {
  ref: any
}

const CLOSE_ICON = DEFAULT_CONTROLS_WITH_CREATION[3]

const settings = ({ id, update, config, setPanelId }: Controls) => (
  <Icon
    name={'Settings'}
    key={id}
    className={style.settingsIcon}
    onClick={() => {
      config.first === 'panel-settings'
        ? update({
            ...(config.second as Object),
          })
        : update({
            direction: 'row',
            first: 'panel-settings',
            second: config,
            splitPercentage: 33,
          })
      setPanelId(
        id === 'panel-map'
          ? PANEL_ID_MAP
          : id === 'panel-chart'
          ? PANEL_ID_CHART
          : PANEL_ID_METADATA
      )
    }}
  />
)

const ResetControl = ({ onClick }: { onClick: () => void }) => (
  <Icon name={'Reset'} className={style.resetIcon} onClick={onClick} />
)

const resetChart = (id: string, chartRef: any) => (
  <ResetControl
    key={`${id}-reset-chart`}
    onClick={() => {
      if (chartRef) {
        chartRef.resetZoom()
      }
    }}
  />
)

const resetMap = (id: string, mapRef: RefObject<any>) => (
  <ResetControl
    key={`${id}-reset-map`}
    onClick={() => {
      if (mapRef && mapRef.current) {
        mapRef.current.initPosition()
      }
    }}
  />
)

const controls = {
  settingsPanel: () => [CLOSE_ICON],
  mapPanel: ({ id, ref, update, config, setPanelId }: ControlsWithRef) => [
    resetMap(id, ref),
    settings({ id, update, config, setPanelId }),
    CLOSE_ICON,
  ],
  metadataPanel: ({ id, update, config, setPanelId }: Controls) => [
    settings({ id, update, config, setPanelId }),
    CLOSE_ICON,
  ],
  chartPanel: ({ id, update, config, setPanelId, ref }: ControlsWithRef) => [
    resetChart(id, ref),
    settings({ id, update, config, setPanelId }),
    CLOSE_ICON,
  ],
}

export default controls
