interface tooltipCallbacksProps {
  unit?: string
  formatValue?: (value: number | undefined) => string | undefined
}
export const tooltipCallbacks = ({
  unit = '',
  formatValue,
}: tooltipCallbacksProps) => {
  return {
    label: (context: any) => {
      const label = context.label || context.dataset.label
      const currentValue = context.raw
      let total = 0
      const datasets = context.chart.data.datasets

      for (let i = 0; i < datasets.length; i++) {
        total += datasets[i].data.reduce((a: number, b: number) => a + b, 0)
      }

      const percentage = parseFloat(((currentValue / total) * 100).toFixed(1))

      return `${label}: ${
        formatValue ? formatValue(currentValue) : currentValue
      }${unit} (${percentage}%)`
    },
  }
}
