import { create } from 'zustand'
import { Metadata } from '../utils/protobufParse'
import { Chart } from 'chart.js'

export type Setting = {
  color: string
  display: boolean
}

export type MetadataKeys =
  | 'steering_angle_normalized'
  | 'gas_pedal_normalized'
  | 'brake_pedal_normalized'
  | 'gear'
  | 'speed'
  | 'turn_signal'
  | 'hp_loc_latitude'
  | 'hp_loc_longitude'
  | 'hp_loc_altitude'
  | 'heading'
  | 'position_covariance'
  | 'heading_error'
  | 'steering_angle'
  | 'acceleration'

export type ChartKeys =
  | 'steering_angle_normalized'
  | 'gas_pedal_normalized'
  | 'brake_pedal_normalized'
  | 'speed'
  | 'steering_angle'
  | 'acceleration'

export type MetadataSettings = Record<MetadataKeys, Setting>
export type ChartSettings = Record<ChartKeys, Setting>

const DEFAULT_METADATA_SETTINGS: MetadataSettings = {
  steering_angle_normalized: {
    color: '#ff82b7',
    display: true,
  },
  gas_pedal_normalized: {
    color: '#ff9c57',
    display: true,
  },
  brake_pedal_normalized: {
    color: '#6faeff',
    display: true,
  },
  gear: {
    color: '#007a7a',
    display: true,
  },
  speed: {
    color: '#d66700',
    display: true,
  },
  turn_signal: {
    color: '#00803e',
    display: true,
  },
  hp_loc_latitude: {
    color: '#410000',
    display: true,
  },
  hp_loc_longitude: {
    color: '#d72a1d',
    display: true,
  },
  hp_loc_altitude: {
    color: '#d60081',
    display: true,
  },
  heading: {
    color: '#ff9c57',
    display: true,
  },
  position_covariance: {
    color: '#0070cc',
    display: true,
  },
  heading_error: {
    color: '#006666',
    display: true,
  },
  steering_angle: {
    color: '#00315d',
    display: true,
  },
  acceleration: {
    color: '#884fd8',
    display: true,
  },
}

const DEFAULT_CHART_SETTINGS: ChartSettings = {
  steering_angle_normalized: {
    color: '#ff82b7',
    display: true,
  },
  gas_pedal_normalized: {
    color: '#ff9c57',
    display: true,
  },
  brake_pedal_normalized: {
    color: '#6faeff',
    display: true,
  },
  speed: {
    color: '#d66700',
    display: true,
  },
  steering_angle: {
    color: '#00315d',
    display: true,
  },
  acceleration: {
    color: '#884fd8',
    display: true,
  },
}

export type MapSettingsKeys = 'map_features' | 'incidents'

export type MapSettings = Record<MapSettingsKeys, Setting>

const DEFAULT_MAP_SETTINGS: MapSettings = {
  map_features: {
    color: '#0070cc',
    display: true,
  },
  incidents: {
    color: '#d72a1d',
    display: true,
  },
}

interface MetadataStoreProps {
  chartSettings: ChartSettings
  metadataSettings: MetadataSettings
  mapSettings: MapSettings
  chart: Chart | null
  url: string
  metadata: Metadata
  seconds: number[]
  loadingFinished: boolean
}

interface MetadataStoreState {
  chartSettings: ChartSettings
  metadataSettings: MetadataSettings
  mapSettings: MapSettings
  chart: Chart | null
  url: string
  seconds: number[]
  metadata: Metadata
  loadingFinished: boolean
  updateUrl: (url: string) => void
  updateChart: (chart: Chart) => void
  updateMetadata: (metadata: Metadata) => void
  updateSeconds: (seconds: number[]) => void
  updateMetadataSettings: (settings: MetadataSettings) => void
  updateChartSettings: (settings: ChartSettings) => void
  updateMapSettings: (mapSettings: MapSettings) => void
  updateMetadataLoadingFinished: (loadingFinished: boolean) => void
  reset: () => void
}

const initialState: MetadataStoreProps = {
  chartSettings: DEFAULT_CHART_SETTINGS,
  metadataSettings: DEFAULT_METADATA_SETTINGS,
  mapSettings: DEFAULT_MAP_SETTINGS,
  loadingFinished: false,
  chart: null,
  url: '',
  metadata: {} as Metadata,
  seconds: [],
}

export const useMetadataStore = create<MetadataStoreState>((set) => ({
  chartSettings: DEFAULT_CHART_SETTINGS,
  metadataSettings: DEFAULT_METADATA_SETTINGS,
  mapSettings: DEFAULT_MAP_SETTINGS,
  chart: null,
  url: '',
  loadingFinished: false,
  metadata: {} as Metadata,
  seconds: [],
  updateUrl: (url) =>
    set(() => ({
      url,
    })),
  updateMetadata: (metadata) =>
    set(() => ({
      metadata,
    })),
  updateSeconds: (seconds) =>
    set(() => ({
      seconds,
    })),
  updateChart: (chart) =>
    set(() => ({
      chart,
    })),
  updateMetadataSettings: (metadataSettings) =>
    set(() => ({
      metadataSettings,
    })),
  updateChartSettings: (chartSettings) =>
    set(() => ({
      chartSettings,
    })),
  updateMapSettings: (mapSettings) =>
    set(() => ({
      mapSettings,
    })),
  updateMetadataLoadingFinished: (loadingFinished) =>
    set(() => ({
      loadingFinished,
    })),
  reset: () => {
    set(initialState)
  },
}))
