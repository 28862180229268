import React from 'react'
import style from './style.less'
import Typography from '@yaak/components/src/Typography'
import { TypographyTypes } from '@yaak/components/src/Typography/Typography'
import { useLocation, useNavigate } from 'react-router-dom'

const Header = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const path = location.pathname
  return (
    <div className={style.header}>
      <Typography
        className={
          !path.startsWith('/dataset/') ? style.headerOpacity : undefined
        }
        type={TypographyTypes.headline}
        onClick={() => navigate(-1)}
      >
        {'Dataset'}
      </Typography>
      <Typography
        className={
          !path.startsWith('/metrics') ? style.headerOpacity : undefined
        }
        type={TypographyTypes.headline}
        onClick={() => navigate('/metrics')}
      >
        {'Metrics'}
      </Typography>
    </div>
  )
}

export default Header
