import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Scatter } from 'react-chartjs-2'
import { ChartKeys, useMetadataStore } from '../../stores/MetadataStore'
import { usePlayerStore } from '../../stores/PlayerStore'
import { useShallow } from 'zustand/react/shallow'
import zoomPlugin from 'chartjs-plugin-zoom'
import annotationPlugin from 'chartjs-plugin-annotation'
import { subtractTime, toSec } from '../../utils/time'
import { getRelativePosition } from 'chart.js/helpers'
import VerticalBar from './VerticalBar'
import { calculateAcceleration, Timestamp } from '../../utils/protobufParse'
import style from './style.less'
import { getXValueAtPixel } from './utils'
import { useVideosStore } from '../../stores/VideosStore'
import { toDurationTimeMs } from '@yaak/admin/src/helpers/time'
import { useSearchParams } from 'react-router-dom'
import { renderExternalTooltip } from './tooltip'

const usePrevious = (value: any) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin,
  annotationPlugin
)

interface Data {
  x: number
  y: number
  time_stamp: Timestamp
}

const createData = (data: any, startTime: Timestamp, value: number): Data => {
  return {
    x: toSec(subtractTime(data.time_stamp, startTime)),
    y: value || 0,
    time_stamp: data.time_stamp,
  }
}

interface VehicleMotionChartProps {
  sessionId?: string
}

const VehicleMotionChart: React.FunctionComponent<VehicleMotionChartProps> = ({
  sessionId,
}) => {
  const chartRef = useRef<any>(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const { metadata, updateChart, settings } = useMetadataStore(
    useShallow((state) => ({
      metadata: state.metadata,
      settings: state.chartSettings,
      updateChart: state.updateChart,
    }))
  )

  const { update, begin, end } = usePlayerStore(
    useShallow((state) => ({
      end: state.end,
      begin: state.begin,
      update: state.update,
    }))
  )

  const { session } = useVideosStore(
    useShallow((state) => ({
      session: state.session,
    }))
  )

  const [data, setData] = useState<Data[][]>([])
  const [length, setLength] = useState<number>()
  const [zoom, setZoom] = useState<number>()
  const [min, setMin] = useState<number>(0)
  const [max, setMax] = useState<number>(0)
  const prev = usePrevious(length)

  const datasets = useMemo(
    () => [
      {
        label: 'brake_pedal_normalized',
        data: [],
        borderColor: '#6faeff',
        backgroundColor: '#6faeff',
        borderWidth: 1,
        pointRadius: 1.2,
        pointHoverRadius: 3,
        fill: false,
        showLine: true,
        spanGaps: true,
        pointBorderColor: 'transparent',
      },
      {
        label: 'gas_pedal_normalized',
        data: [],
        borderColor: '#ff9c57',
        backgroundColor: '#ff9c57',
        borderWidth: 1,
        pointRadius: 1.2,
        fill: false,
        pointHoverRadius: 3,
        showLine: true,
        spanGaps: true,
        pointBorderColor: 'transparent',
      },
      {
        label: 'steering_angle_normalized',
        data: [],
        borderColor: '#ff82b7',
        backgroundColor: '#ff82b7',
        borderWidth: 1,
        fill: false,
        pointRadius: 1.2,
        pointHoverRadius: 3,
        showLine: true,
        spanGaps: true,
        pointBorderColor: 'transparent',
      },
      {
        label: 'speed',
        data: [],
        borderColor: '#d66700',
        backgroundColor: '#d66700',
        borderWidth: 1,
        pointRadius: 1.2,
        pointHoverRadius: 3,
        fill: false,
        showLine: true,
        spanGaps: true,
        pointBorderColor: 'transparent',
      },
      {
        label: 'steering_angle',
        data: [],
        borderColor: '#00315d',
        backgroundColor: '#00315d',
        borderWidth: 1,
        pointRadius: 1.2,
        pointHoverRadius: 3,
        fill: false,
        showLine: true,
        spanGaps: true,
        pointBorderColor: 'transparent',
      },
      {
        label: 'acceleration',
        data: [],
        borderColor: '#884fd8',
        backgroundColor: '#884fd8',
        borderWidth: 1,
        pointRadius: 1.2,
        pointHoverRadius: 3,
        fill: false,
        showLine: true,
        spanGaps: true,
        pointBorderColor: 'transparent',
      },
    ],
    []
  )

  useEffect(() => {
    const slicedData = prev
      ? metadata.vehicleMotion?.slice(prev)
      : metadata.vehicleMotion
    setLength(metadata.vehicleMotion?.length)

    const start = begin
      ? {
          seconds: new Date(session?.startTimestamp).getTime() / 1000,
          nanos: 0,
        }
      : metadata.vehicleMotion?.[0]?.time_stamp
    const breakPedalNormalized: Data[] = slicedData?.map((vM) => {
      return createData(vM, start, vM.brake_pedal_normalized)
    })
    const gasPedalNormalized = slicedData?.map((vM) =>
      createData(vM, start, vM.gas_pedal_normalized)
    )
    const steeringAngleNormalized = slicedData?.map((vM) =>
      createData(vM, start, vM.steering_angle_normalized)
    )
    const speed = slicedData?.map((vM) => createData(vM, start, vM.speed))
    const steeringAngle = slicedData?.map((vM) =>
      createData(vM, start, vM.steering_angle)
    )
    const acceleration = slicedData?.map((vM) =>
      createData(vM, start, calculateAcceleration(vM))
    )

    setData([
      breakPedalNormalized,
      gasPedalNormalized,
      steeringAngleNormalized,
      speed,
      steeringAngle,
      acceleration,
    ])
  }, [metadata, prev, begin])

  useEffect(() => {
    if (
      begin &&
      session?.offsetURLStartTimestamp &&
      session?.offsetURLEndTimestamp
    ) {
      setMin(
        toDurationTimeMs(
          session?.startTimestamp,
          session?.offsetURLStartTimestamp
        ) / 1000
      )
      setMax(
        toDurationTimeMs(
          session?.startTimestamp,
          session?.offsetURLEndTimestamp
        ) / 1000
      )
    } else {
      setMax(
        toDurationTimeMs(session?.startTimestamp, session?.endTimestamp) / 1000
      )
    }
  }, [session, begin])

  useEffect(() => {
    if (data.length > 0 && chartRef.current) {
      datasets.forEach((dataset: any, i: number) => {
        if (data[i]) {
          for (let k = 0; k < data[i].length; k++) {
            // Manually push items one by one to avoid maximum call stack
            dataset.data.push(data[i][k])
          }
        }
      })
      chartRef.current.update('none')
    }
  }, [data, chartRef])

  useEffect(() => {
    if (chartRef.current) {
      Object.keys(settings).forEach((s) => {
        const dataset = chartRef.current.data.datasets.filter(
          (d: any) => d.label === s
        )[0]
        if (dataset) {
          dataset.hidden = !settings[s as ChartKeys].display
        }
      })
      chartRef.current.zoom = zoom
      chartRef.current.update('none')
    }
  }, [settings, chartRef, zoom])

  useEffect(() => {
    return () => {
      chartRef.current?.destroy()
    }
  }, [chartRef])

  const zoomOptions = useMemo(
    () => ({
      pan: {
        mode: 'xy',
        enabled: true,
        speed: 20,
        threshold: 10,
      },
      zoom: {
        wheel: {
          enabled: true,
        },
        pinch: {
          enabled: true,
        },
        mode: 'x',
        sensitivity: 3,
        speed: 0.1,
        onZoom: ({ chart }: { chart: ChartJS }) => {
          setZoom(chart.getZoomLevel())
        },
      },
    }),
    [data, setZoom]
  )

  const onMouseMove = useCallback(
    (event: React.MouseEvent) => {
      const rect = event.currentTarget.getBoundingClientRect()
      const mouseX = event.clientX - rect.left
      const seconds = getXValueAtPixel(chartRef.current.scales.x, mouseX)
      update({
        hoverSync: seconds,
      })
    },
    [chartRef.current]
  )

  const options = useMemo(() => {
    return {
      animation: false,
      devicePixelRatio: 2,
      pointRadius: 1.5,
      maintainAspectRatio: false,
      responsive: true,
      spanGaps: true,
      showLine: false,
      plugins: {
        annotation: {
          annotations: {
            box1: {
              type: 'box',
              xMin: begin,
              xMax: end,
              backgroundColor: 'rgb(89, 96, 222, 0.12)',
              borderColor: 'transparent',
            },
          },
        },
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          external: renderExternalTooltip,
          position: 'nearest',
          callbacks: {
            title: () => '',
            label: (context: any) => {
              let label = context.dataset.label || ''
              if (label) {
                if (label === 'steering_angle') {
                  label += `${context.formattedValue} (deg)`
                } else if (label === 'speed') {
                  label += `${context.formattedValue} (km/h)`
                } else if (label === 'acceleration') {
                  label += `${context.formattedValue} (m/s²)`
                } else {
                  label += context.formattedValue
                }
              }
              return label
            },
          },
        },
        zoom: zoomOptions,
      },
      interaction: {
        intersect: false,
        mode: 'index',
      },
      elements: { line: { tension: 0 } },
      scales: {
        x: {
          axis: 'x',
          beginAtZero: false,
          bounds: 'ticks',
          clip: true,
          display: true,
          grace: 0,
          id: 'x',
          offset: false,
          reverse: false,
          position: 'bottom',
          type: 'linear',
          ticks: {
            autoSkip: true,
          },
          min,
          max,
        },
        y: {
          axis: 'y',
          beginAtZero: false,
          bounds: 'ticks',
          clip: true,
          display: true,
          grace: 0,
          id: 'y',
          offset: false,
          position: 'left',
          reverse: false,
          type: 'linear',
        },
      },
    }
  }, [zoomOptions, min, max, begin, end])

  useEffect(() => {
    if (chartRef.current && sessionId && max !== 0) {
      updateChart(chartRef.current)
    }
  }, [chartRef, sessionId, min, max])

  const onClick = useCallback(
    (event: any) => {
      const canvasPosition = getRelativePosition(event, chartRef.current)
      const seconds = chartRef.current.scales.x.getValueForPixel(
        canvasPosition.x
      )
      update({
        offset: seconds,
        jump: seconds,
      })
      searchParams.set('offset', seconds.toString())
      setSearchParams(searchParams)
    },
    [chartRef, searchParams]
  )

  return (
    <div className={style.chartContainer} onMouseMove={onMouseMove}>
      <div className={style.chart}>
        <Scatter
          ref={chartRef}
          options={options as any}
          data={{
            datasets: [...datasets],
          }}
          onClick={onClick}
        />
      </div>
      <VerticalBar color={'#000'} />
      <VerticalBar hover={true} color={'#3892f3'} />
    </div>
  )
}

export default memo(VehicleMotionChart)
